import {
  Button,
  useDisclosure,
  Text,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { unwrapResult } from '@reduxjs/toolkit';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { generateNewSecret } from '../../../state/apiKey/apiKeySlice';
import { useAppDispatch } from '../../../state/createStore';
import { PrimaryButton } from '../../buttons';
import 'react-toastify/dist/ReactToastify.css';

const NewSecretGenerationModal = (): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { key } = useSelector((state: any) => state.apiKeys);
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const newSecret = async () => {
    try {
      setIsLoading(true);
      await dispatch(generateNewSecret(key?.apiKeyId)).then(unwrapResult);
    } catch (error) {
      toast.error(
        `Your new API Secret key could not be generated. ${error.message}`
      );
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button
        borderRadius="55px"
        paddingX="25px"
        paddingY="25px"
        bgColor="#4fc1ff"
        color="#fff"
        _hover={{
          bgColor: '#000',
        }}
        isLoading={isLoading}
        onClick={onOpen}
        alignSelf="flex-end"
      >
        Generate New Secret
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Secret Key Generation Confirmation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column">
              <Text marginBottom="5px">
                Clicking confirm will generate a new secret for this API
              </Text>
              <strong>
                We only show the the secret once, we recommend you save it
                somewhere secure.
              </strong>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} variant="ghost">
              Cancel
            </Button>
            <PrimaryButton isLoading={isLoading} onClick={newSecret}>
              Confirm & Generate
            </PrimaryButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ToastContainer theme="colored" />
    </>
  );
};

export default NewSecretGenerationModal;

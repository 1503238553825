/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import {
  Text,
  Heading,
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  IconButton,
  InputRightElement,
  Stack,
  Skeleton,
  Divider,
  useClipboard,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { CloseIcon } from '@chakra-ui/icons';
import { unwrapResult } from '@reduxjs/toolkit';
import { API, Auth } from 'aws-amplify';

import GetStartedSection from './sections/getStarted/getStarted';
import ProjectsSection from './sections/projects/projects';
import { SubSection } from '../layout/sections';
import { useAppDispatch } from '../../state/createStore';
import { getExistingKeys } from '../../state/apiKey/apiKeySlice';
import NewSecretGenerationModal from '../modals/generateNewSecret/generateNewSecret';

const Dashboard = (): JSX.Element => {
  // state
  const { user } = useSelector((state: any) => state.user);
  const { currentAccount } = useSelector((state: any) => state.account);
  const { projects } = useSelector((state: any) => state.projects);
  const { key, loading } = useSelector((state: any) => state.apiKeys);
  const keyCopy = useClipboard(key?.key);
  const keySecret = useClipboard(key?.secret);
  const [showModal, setShowModal] = React.useState(true);
  const dispatch = useAppDispatch();
  const [gettingStripeLink, setGettingStripeLink] = React.useState(false);

  const { pricePlan, currentPeriod } = currentAccount || {
    pricePlan: 'Individual Starter',
    currentPeriod: 'test',
  };

  console.log(currentAccount);

  // handlers
  const handleModalClick = () => setShowModal(!showModal);

  const getStripeLink = async () => {
    setGettingStripeLink(true);
    try {
      const token = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      const myInit = {
        body: {
          return_url: window.location.href,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await API.post(
        'openscreen',
        `billing/session/${currentAccount.accountId}`,
        myInit
      );
      setGettingStripeLink(false);
      window.location.href = res.redirectUrl;
    } catch (error) {
      setGettingStripeLink(false);
      console.error(error);
    }
  };

  // effects
  React.useEffect(() => {
    if (currentAccount?.accountId) {
      dispatch(getExistingKeys(currentAccount?.accountId)).then(unwrapResult);
    }
  }, [currentAccount?.accountId, dispatch]);

  return (
    <Box marginX="40px" height="100%">
      <Flex height="100%" direction="column">
        <Skeleton
          w={{ base: '100%', md: '70vw' }}
          display={
            (projects.length === 0 || !key?.key) && showModal ? 'flex' : 'none'
          }
          marginBottom="40px"
          isLoaded={!loading}
        >
          <Flex bgColor="#fff" padding="10px" direction="column" w="100%">
            <Flex marginLeft="20px" direction="column" justify="space-between">
              <Flex justify="space-between">
                <Heading fontSize="22pt">
                  Hey {user?.given_name}, let's get started with Openscreen
                </Heading>
                <IconButton
                  onClick={handleModalClick}
                  variant="ghost"
                  _hover={{
                    color: 'teal',
                  }}
                  colorScheme="black"
                  aria-label="close get started section"
                  fontSize="10px"
                  icon={<CloseIcon />}
                />
              </Flex>
              <Box marginLeft="10px">
                <GetStartedSection noKey={!key?.key} />
              </Box>
            </Flex>
          </Flex>
        </Skeleton>
        <Flex
          justify="space-between"
          marginBottom="40px"
          direction={{ base: 'column', xl: 'row' }}
          w={{ base: '100%', md: '70vw' }}
        >
          <Skeleton
            marginRight="20px"
            width="100%"
            bgColor="#fff"
            isLoaded={!loading}
          >
            {/* ACCOUNT SUBSECTION */}
            <Flex w="100%" maxW="900px" padding="20px" direction="column">
              <Heading fontSize="20pt">Account Information</Heading>
              <Divider height="15px" orientation="horizontal" />
              <Box marginLeft="10px">
                <Stack marginTop="20px" spacing={7}>
                  <Flex direction={{ base: 'column', md: 'row' }} height="100%">
                    <Flex align="center">
                      <Text fontWeight="bold" minW="100px" whiteSpace="nowrap">
                        First Name
                      </Text>
                      <Input
                        variant="filled"
                        marginX="5px"
                        readOnly
                        value={user?.given_name}
                      />
                    </Flex>
                    <Flex
                      marginTop={{ base: '25px', md: '0px' }}
                      align="center"
                    >
                      <Text
                        fontWeight="bold"
                        whiteSpace="nowrap"
                        marginX={{ base: '0px', md: '10px' }}
                        marginRight={{ base: '20px', md: '15px' }}
                      >
                        Last Name
                      </Text>
                      <Input
                        variant="filled"
                        marginX={{ base: '5px', md: '10px' }}
                        readOnly
                        value={user?.family_name}
                      />
                    </Flex>
                  </Flex>
                  <Flex align="center">
                    <Text fontWeight="bold" minW="100px">
                      Company
                    </Text>
                    <Input
                      variant="filled"
                      readOnly
                      marginX="5px"
                      value={currentAccount?.companyName}
                    />
                  </Flex>
                  <Flex align="center">
                    <Text fontWeight="bold" maxW="100px">
                      Account Number
                    </Text>
                    <Input
                      readOnly
                      variant="filled"
                      marginX="5px"
                      value={currentAccount?.accountId}
                    />
                  </Flex>
                </Stack>
              </Box>
            </Flex>
          </Skeleton>
          <Skeleton
            marginLeft={{ base: '0px', xl: '20px' }}
            width={{ base: '100%', xl: '85%' }}
            marginTop={{ base: '40px', xl: '0px' }}
            bgColor="#fff"
            isLoaded={!loading}
          >
            <SubSection>
              <Heading fontSize="20pt">Subscription Plan</Heading>
              <Divider height="15px" orientation="horizontal" />
              <Flex marginLeft="10px" marginTop="20px" direction="column">
                <Stack justify="space-between">
                  <Flex direction="column">
                    <Flex
                      w="100%"
                      marginBottom="20px"
                      align="center"
                      justify="space-between"
                    >
                      <Text
                        w="130px"
                        fontWeight="bold"
                        marginRight="15px"
                        whiteSpace="nowrap"
                      >
                        Current Plan
                      </Text>
                      <Flex
                        direction={{ base: 'column', md: 'row' }}
                        align="flex-end"
                        justify="flex-end"
                        width="100%"
                      >
                        <Input
                          variant="filled"
                          width="100%"
                          _disabled={{ textColor: 'black' }}
                          disabled
                          readOnly
                          value={pricePlan?.name}
                        />
                        <Button
                          alignSelf="end"
                          minW="140px"
                          w={{ base: '100%', md: '35%' }}
                          onClick={getStripeLink}
                          paddingX="25px"
                          paddingY="15px"
                          bgColor="#4fc1ff"
                          marginX={{ base: '0px', md: '5px' }}
                          marginY={{ base: '5px', md: '0px' }}
                          color="#fff"
                          isLoading={gettingStripeLink}
                          _hover={{
                            bgColor: '#000',
                          }}
                        >
                          Change Plan
                        </Button>
                      </Flex>
                    </Flex>
                    <Flex marginBottom="20px" maxW="1000px" align="center">
                      <Text maxW="130px" fontWeight="bold" whiteSpace="nowrap">
                        Current Usage
                      </Text>
                      <Stack
                        bgColor="gray.100"
                        marginLeft="6px"
                        w="100%"
                        _hover={{
                          bgColor: 'gray.200',
                        }}
                        paddingY="5px"
                        spacing={2}
                        borderRadius="5px"
                      >
                        <Flex paddingX="15px">
                          <Text>
                            {currentPeriod?.users} of {pricePlan?.users}
                          </Text>
                          <Text marginLeft="0.3rem">Users</Text>
                        </Flex>
                        <Flex paddingX="15px">
                          <Text>
                            {currentPeriod?.projects} of{' '}
                            {pricePlan?.projects === -1
                              ? 'Unlimited'
                              : pricePlan?.projects}
                          </Text>
                          <Text marginLeft="0.3rem">Projects</Text>
                        </Flex>
                        <Flex paddingX="15px">
                          <Text>
                            {currentPeriod?.qrCodes} of {pricePlan?.qrCodes}
                          </Text>
                          <Text marginLeft="0.3rem">QR Codes</Text>
                        </Flex>
                        <Flex paddingX="15px">
                          <Text>
                            {currentPeriod?.scansUsed} of{' '}
                            {pricePlan?.monthlyScans === -1
                              ? 'Unlimited'
                              : pricePlan?.monthlyScans}
                          </Text>
                          <Text marginLeft="0.3rem">Monthly Scans</Text>
                        </Flex>
                      </Stack>
                    </Flex>
                  </Flex>
                </Stack>
              </Flex>
            </SubSection>
          </Skeleton>
        </Flex>
        <Skeleton
          marginBottom="50px"
          bgColor="white"
          w={{ base: '100%', md: '70vw' }}
          isLoaded={!loading}
        >
          <SubSection
            style={{
              marginBottom: '20px',
            }}
          >
            <Heading fontSize="20pt">API Key</Heading>
            <Divider height="15px" orientation="horizontal" />

            <Stack spacing={6}>
              <Box
                marginTop="20px"
                marginLeft="10px"
                display={key ? 'block' : 'none'}
              >
                <Flex maxW="100%" direction="column">
                  <Flex marginRight="5px" align="center">
                    <Text fontWeight="bold" minW="100px">
                      Key
                    </Text>
                    <InputGroup>
                      <Input
                        variant="filled"
                        marginX="5px"
                        readOnly
                        value={keyCopy.value}
                      />
                      <InputRightElement width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={keyCopy.onCopy}>
                          {keyCopy.hasCopied ? 'Copied' : 'Copy'}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </Flex>
                  {key?.secret ? (
                    <Flex marginY="15px" align="center">
                      <Text whiteSpace="nowrap" minW="100px">
                        Secret
                      </Text>
                      <InputGroup marginY="20px" marginX="5px" size="md">
                        <Input
                          variant="filled"
                          readOnly
                          _disabled={{ textColor: 'black' }}
                          value={keySecret.value}
                        />
                        <InputRightElement width="4.5rem">
                          <Button
                            h="1.75rem"
                            size="sm"
                            onClick={keySecret.onCopy}
                          >
                            {keySecret.hasCopied ? 'Copied' : 'Copy'}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    </Flex>
                  ) : (
                    <Flex marginTop="30px" marginRight="5px" justify="flex-end">
                      <NewSecretGenerationModal />
                    </Flex>
                  )}
                </Flex>
              </Box>
              <Box display={key ? 'none' : 'block'}>
                <Flex align="flex-end" maxW="500px">
                  {/* <NewKeyGenerationModal /> */}
                  <Text fontWeight="bold">
                    There are no API keys associated with this account, please
                    use the 'Get Started' section to generate a new key
                  </Text>
                </Flex>
              </Box>
            </Stack>
          </SubSection>
        </Skeleton>
        <Skeleton
          w={{ base: '100%', md: '70vw' }}
          bgColor="white"
          marginBottom="40px"
          isLoaded={!loading}
        >
          <SubSection
            style={{
              minH: '300px',
            }}
          >
            <Heading fontSize="20pt" marginBottom="7px">
              Projects
            </Heading>
            <Divider height="15px" orientation="horizontal" />
            <ProjectsSection />
          </SubSection>
        </Skeleton>
      </Flex>
    </Box>
  );
};

export default Dashboard;

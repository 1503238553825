import { Button, Flex, Text } from '@chakra-ui/react';
import * as React from 'react';
import { Link } from 'gatsby';
import { useSelector } from 'react-redux';
import ProjectCard from './projectCard/projectCard';

const ProjectsSection = (): JSX.Element => {
  const { projects } = useSelector((state: any) => state.projects);

  return (
    <Flex direction="column" marginY="15px">
      <Text marginBottom="5px">
        Create QR Codes, store related assets and contacts using projects
      </Text>
      {/* Render five projects */}
      <Flex
        direction={{ base: 'column', md: 'row' }}
        marginLeft="5px"
        marginY="15px"
        maxW="995px"
        width="100%"
      >
        <Flex margin="5px">
          <Link to="project/create">
            <Button
              color="#fff"
              borderRadius="55px"
              paddingX="25px"
              paddingY="25px"
              bgColor="#4fc1ff"
              _hover={{
                bgColor: '#000',
              }}
            >
              Create a New Project
            </Button>
          </Link>
        </Flex>
        {projects.slice(0, 4).map((e: { name: string; projectId: string }) => {
          return (
            <ProjectCard key={e.projectId} name={e.name} id={e.projectId} />
          );
        })}
      </Flex>
    </Flex>
  );
};

export default ProjectsSection;

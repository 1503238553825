import { Button, Flex } from '@chakra-ui/react';
import { Link } from 'gatsby';
import * as React from 'react';

interface ProjectCardProps {
  name: string;
  id?: string;
}

const ProjectCard = ({ name, id }: ProjectCardProps): JSX.Element => {
  return (
    <Flex variant="ghost" margin="5px">
      <Link to={`/app/project/view/${id}`}>
        <Button
          borderRadius="55px"
          paddingX="25px"
          paddingY="25px"
          bgColor="#000"
          color="#fff"
          _hover={{
            bgColor: '#4fc1ff',
          }}
        >
          {name}
        </Button>
      </Link>
    </Flex>
  );
};

export default ProjectCard;

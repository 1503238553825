/* eslint-disable react/no-unescaped-entities */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { navigate } from 'gatsby';
import { Prism } from '@mantine/prism';
import * as React from 'react';
import NewKeyGenerationModal from '../../../modals/generateNewKey/generateNewKey';
import { PrimaryButton } from '../../../buttons';

interface GettingStartedProps {
  noKey?: boolean;
}

const npmInstallSnippet = `  npm i @openscreen/sdk`;

// const axiosInstall = `  npm install axios`;

const nodeSDKCode = `
const Openscreen = require("@openscreen/sdk");
const os = new Openscreen().config({
  key: "ISkcTKYYF2UdooXLpQ",
  secret: "ZkTQtY5NCaa8GlnGtvZs2Su8",
  environment: "api-development",
});

const projectId = "b77398df-7cce-4dff-a5e8-9b116fd7aadc";

const generateDynamicQR = async (event) => {
  try {
    const { format, intent, foreground, size, description } = JSON.parse(
      event.body
    );
    const res = await os
      .project(projectId)
      .assets()
      .create({ name: description, description });
    const { assetId } = res.asset;

    let newIntent = intent;
    if (intent.startsWith("https://")) {
      newIntent = intent.slice(8);
    }
    const token = await os
      .asset(assetId)
      .qrCodes()
      .create({
        intentType: "DYNAMIC_REDIRECT",
        intent: \`https://\${newIntent}\`
      });

    const { qrCodeId } = token;

    const qr = await os.qrCode(qrCodeId).get({
      format,
      dataUrl: true,
      darkColor: foreground.hex,
      lightColor: "#fff",
      size,
    });

    return {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
      },
      statusCode: 200,
      body: JSON.stringify(qr),
    };
  } catch (error) {
    console.log(error);
    return {
      statusCode: 400,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify("ERROR"),
    };
  }
};
// <----- For more information, please check out or docs ----->`;
// const restAPICode = `  import axios from "axios";

// // Creates an asset
// const newAsset = await axios.post(
//   "openscreen.com/projects/"PROJECT_ID"/assets", {
//   {
//     "name": "Card Name",
//     "customAttributes": {
//       "batchId": "38C",
//     },
//     // Create multiple tokens for the asset
//     "tokens": [
//       {
//         "locatorKeyType": "SHORT_URL"
//       },
//     ]
//   }
// })

// // newAsset will return with the tokens (short url for the qr code) for the asset
// // <----- For more information, please check out or docs ----->
// `;

const GetStartedSection = ({
  noKey = false,
}: GettingStartedProps): JSX.Element => {
  const toProjectCreation = () => {
    navigate('/app/project/create');
  };

  return (
    <Flex paddingBottom="30px" paddingTop="20px">
      <Accordion allowToggle allowMultiple w="100%">
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Text fontSize="15pt">Get your API key</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text marginY="10px">
              {noKey
                ? `First off, you'll need to create a new API key to authorize
              changes on assets, tokens and more. Keep in mind we only show the
              secret key ONCE, with that in mind we recommend you save it
              somewhere secure.`
                : `Seems like you already have an API key, if you need to generate a new secret use the GENERATE NEW SECRET button below on the dashboard`}
            </Text>
            <Box display={noKey ? 'inherit' : 'none'}>
              <NewKeyGenerationModal />
            </Box>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Text fontSize="15pt">Create your first project</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text marginY="10px">
              To properly utilise our vast tool set, you'll need to create a
              project which will give you a project ID to work with and help get
              on your way with openscreen
            </Text>
            <PrimaryButton onClick={toProjectCreation}>
              Create Project
            </PrimaryButton>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Text fontSize="15pt">Make your first call</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Tabs colorScheme="teal" variant="solid-rounded">
              <TabList>
                <Tab
                  _selected={{
                    bgColor: '#3CAFD7',
                    colorScheme: 'teal',
                    color: 'white',
                  }}
                >
                  Node SDK
                </Tab>
                {/* <Tab
                  _selected={{
                    bgColor: '#3CAFD7',
                    colorScheme: 'teal',
                    color: 'white',
                  }}
                >
                  REST API
                </Tab> */}
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Prism
                    style={{
                      marginBottom: '5px',
                    }}
                    themeOverride={{ colorScheme: 'dark' }}
                    language="javascript"
                  >
                    {npmInstallSnippet}
                  </Prism>
                  <Prism
                    themeOverride={{ colorScheme: 'dark' }}
                    language="javascript"
                  >
                    {nodeSDKCode}
                  </Prism>
                </TabPanel>
                {/* <TabPanel w="800px">
                  <Prism
                    style={{
                      marginBottom: '5px',
                    }}
                    themeOverride={{ colorScheme: 'dark' }}
                    language="javascript"
                  >
                    {axiosInstall}
                  </Prism>
                  <Prism themeOverride={{ colorScheme: 'dark' }} language="tsx">
                    {restAPICode}
                  </Prism>
                </TabPanel> */}
              </TabPanels>
            </Tabs>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>
  );
};

export default GetStartedSection;

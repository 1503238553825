import {
  Button,
  useDisclosure,
  Text,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { toast, ToastContainer } from 'react-toastify';
import { unwrapResult } from '@reduxjs/toolkit';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { createNewKey } from '../../../state/apiKey/apiKeySlice';
import { useAppDispatch } from '../../../state/createStore';
import { PrimaryButton } from '../../buttons';
import 'react-toastify/dist/ReactToastify.css';

const NewKeyGenerationModal = (): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currentAccount } = useSelector((state: any) => state.account);
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAppDispatch();

  const generateKey = async () => {
    // console.log(currentAccount);
    setIsLoading(true);
    try {
      await dispatch(createNewKey(currentAccount?.accountId)).then(
        unwrapResult
      );
    } catch (error) {
      toast.error(`New API key could not be generated. ${error.message}`);
    }
    setIsLoading(false);
    onClose();
  };

  return (
    <>
      <Button
        borderRadius="55px"
        paddingX="25px"
        paddingY="25px"
        bgColor="#4fc1ff"
        color="#fff"
        isLoading={isLoading}
        onClick={onOpen}
      >
        Generate New Key
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>API Key Generation Confirmation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column">
              <Text marginBottom="5px">
                Clicking confirm will generate an API Key and a secret
              </Text>
              <strong>
                We only show the the secret once, we recommend you save it
                somewhere secure.
              </strong>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <PrimaryButton isLoading={isLoading} onClick={generateKey}>
              Confirm & Generate
            </PrimaryButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ToastContainer theme="colored" />
    </>
  );
};

export default NewKeyGenerationModal;
